@use 'sass:map';

#information_registration_screen {
    &01 {
        .btn-smp {
            top: 280px;
        }
    }

    &02 {
        .btn-smp {
            top: 314px;
            right: 22px;
            left: auto;
            width: 50px;
        }
    }

    &03 {
        @include frame_full_height(1870px);
        @include hide_element_all;

        .btn-pc {
            top: 222px;
            left: 440px;
            width: 100px;
            height: 30px;
        }

        .screen_disable_note p {
            margin-bottom: 110px;
        }
    }

    &04 {
        @include frame_full_height(2300px);
        @include hide_element_all;

        .btn-pc {
            top: 609px;
            left: 435px;
            width: 150px;
        }

        .screen_disable_note p {
            margin-bottom: 110px;
        }
    }

    &05 {
        @include frame_full_height(2300px);
        @include hide_element_all;

        .btn-pc {
            top: 514px;
            left: 664px;
            z-index: 10001;
            width: 75px;
            height: 33px;
        }

        .window_file {
            top: 150px;
            left: 165px;
        }

        .screen_disable_note p {
            margin-bottom: 110px;
        }
    }

    &06 {
        @include frame_full_height(2300px);
        @include hide_element_all;

        .btn-pc {
            top: 205px;
            left: 572px;
        }

        .screen_disable_note p {
            margin-bottom: 110px;
        }
    }

    &07 {
        @include frame_full_height(2300px);
        @include hide_element_all;

        .btn-pc {
            top: 1847px;
            left: 458px;
        }

        .screen_disable_note p {
            margin-bottom: 110px;
        }
    }

    &08 {
        @include hide_element_all;

        .btn-pc {
            top: 417px;
            left: 557px;
        }

        .screen_disable_note p {
            margin-bottom: 110px;
        }
    }

    &09 {
        @include hide_element_all;

        .btn-smp {
            top: 411px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &10 {
        @include hide_element_all;

        .btn-smp {
            top: 626px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &11 {
        @include hide_element_all;

        .btn-smp {
            top: 410px;
            right: 22px;
            left: auto;
            width: 50px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &12 {
        @include hide_element_all;

        .btn-smp {
            top: 469px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &13 {
        @include hide_element_all;

        .btn-smp {
            top: 432px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &14 {
        @include hide_element_all;

        .btn-smp {
            top: 210px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &15 {
        @include hide_element_all;

        .btn-smp {
            top: 295px;
        }

        .btn-smp._b {
            top: 480px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &16 {
        @include hide_element_all;

        .btn-smp {
            top: 494px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &17 {
        @include hide_element_all;

        .btn-smp {
            top: 495px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &18 {
        @include hide_element_all;

        .btn-pc {
            top: 505px;
            left: 50%;
            transform: translateX(-50%);
            width: 445px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }

        #demo_modal2 {
            display: flex;
        }
    }

    &19 {
        @include hide_element_all;

        .btn-smp {
            top: 513px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &19-1 {
        @include hide_element_all;

        .btn-smp {
            top: 380px;
            right: 11px;
            left: auto;
            width: 50px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &19-2 {
        @include hide_element_all;

        .btn-smp {
            top: 326px;
            right: 35px;
            left: auto;
            width: 50px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &19-3 {

        .btn-pc {
            top: 737px;
            left: 442px;
            width: 240px;
        }
    }

    &19-4 {
        @include frame_full_height(1750px);
    }

    &20 {
        @include hide_element_all;

        .btn-pc {
            top: 272px;
            left: 524px;
            width: 125px;
        }

        .screen_disable_note p {
            margin-top: 155px;
        }
    }

    &21 {

        @include frame_full_height(1160px);
        @include hide_element_all;

        .btn-pc {
            top: 868px;
            left: 446px;
            z-index: 9999;
        }

        .screen_disable_note p {
            margin-top: 155px;
        }

        .cdk-overlay-backdrop.ant-modal-mask.cdk-overlay-backdrop-showing {
            z-index: -1;
        }
    }

    &22 {

        @include hide_element_all;

        .btn-pc {
            top: 329px;
            left: 523px;
            width: 125px;
        }

        .screen_disable_note p {
            margin-top: 155px;
        }
    }

    &23 {
        @include frame_full_height(1160px);
        @include hide_element_all;

        .btn-pc {
            top: 869px;
            left: 446px;
            z-index: 9999;
        }

        .screen_disable_note p {
            margin-top: 155px;
        }

        .cdk-overlay-backdrop.ant-modal-mask.cdk-overlay-backdrop-showing {
            z-index: -1;
        }
    }

    &24 {

        @include hide_element_all;

        .btn-pc {
            top: 387px;
            left: 523px;
            width: 125px;
        }

        .screen_disable_note p {
            margin-top: 155px;
        }
    }

    &25 {
        @include frame_full_height(1160px);
        @include hide_element_all;

        .btn-pc {
            top: 868px;
            left: 446px;
            z-index: 9999;
        }

        .screen_disable_note p {
            margin-top: 155px;
        }

        .cdk-overlay-backdrop.ant-modal-mask.cdk-overlay-backdrop-showing {
            z-index: -1;
        }
    }

    &26 {
        @include hide_element_all;

        .btn-pc {
            top: 445px;
            left: 523px;
            width: 125px;
        }

        .screen_disable_note p {
            margin-top: 155px;
        }
    }

    &27 {
        @include frame_full_height(1160px);
        @include hide_element_all;

        .btn-pc {
            top: 868px;
            left: 446px;
            z-index: 9999;
        }

        .screen_disable_note p {
            margin-top: 155px;
        }

        .cdk-overlay-backdrop.ant-modal-mask.cdk-overlay-backdrop-showing {
            z-index: -1;
        }
    }

    &28 {
        @include hide_element_all;

        .btn-pc {
            top: 727px;
            left: 631px;
            width: 117px;
            height: 50px;
        }

        .screen_disable_note p {
            margin-top: 155px;
        }
    }

    &29 {
        @include hide_element_all;
        @include frame_full_height(1300px);

        .btn-pc {
            top: 1013px;
            left: 443px;
            z-index: 9999;
        }

        .screen_disable_note p {
            margin-top: 155px;
        }
    }

    &30 {
        @include hide_element_all;
        @include frame_full_height(1160px);

        .btn-pc {
            top: 115px;
            left: 443px;
            z-index: 9999;
        }

        .screen_disable_note p {
            margin-top: 155px;
        }
    }

    &31 {
        @include hide_element_all;

        .btn-pc {
            top: 289px;
            left: 370px;
            width: 185px;
        }
    }
}

.desktop.safari,
.desktop.chrome {
    #information_registration_screen {
        &10 {
            .btn-smp {
                top: 614px;
            }
        }
    }
}