#demo_frame__smp {
    display: flex;
    flex-wrap: wrap;
    @include container(321px);
    margin: 0 !important;
    width: 321px;
    height: 100%;

    &_header {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        z-index: 2;
        background-color: rgba($color: #000000, $alpha: 0.07);
        padding-right: 45px;
        width: 100%;
        height: 32px;
        color: #fff;

        * {
            margin: 0;
        }

        ._text01 {
            border: 1px solid #fff;
            border-radius: 13px;
            padding: 3px 5px;
            min-width: 125px;
            font-size: 13px;
            line-height: 100%;
            text-align: center;
        }

        ._text02 {
            margin-left: 10px;
            font-size: 15px;
        }
    }

    &_content {
        position: relative;
        z-index: 1;
        margin: 77px auto 0;
        border-radius: 83px;
        padding: 15px 15px 15px 15px;
        width: 301px;
        height: 616px;


        &::before,
        &::after {
            position: absolute;
            background-position: center top;
            background-repeat: no-repeat;
            content: '';
        }

        &::before {
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            @include background($image: 'mobile-frame-1.png');
        }

        &::after {
            top: 15px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            width: 113px;
            height: 24px;
            @include background($image: 'mobile-frame-2.png');
        }

        &_scroll {
            position: relative;
            border-radius: 25px;
            height: 585px;
            overflow-y: auto;

            img {
                height: auto !important;
            }
        }

        .img_wrap {
            position: relative;
            width: 100%;
            height: 100%;
        }

        img {
            display: block;
            margin: 0 auto;
            border-radius: 30px;
            width: 100%;
            max-width: 100%;
            height: 100%;
        }

        &:hover .popup {
            opacity: 1;
            z-index: 999;
        }
    }

    .screen_disable_note::before {
        border-radius: 50px;
    }

    &.active {
        #demo_frame__smp_header {
            border-radius: 0 0 27px 27px;
            background-color: #387F41;
            height: 60px;
        }

        .screen_disable_note {
            display: none;
        }
    }
}