@use 'sass:map';

#user_authentication_screen {
    &01 {
        .btn-smp {
            top: 198px;
        }
    }

    &02 {
        .btn-smp {
            top: 484px;
        }
    }

    &03 {
        .btn-smp {
            top: 348px;
        }
    }

    &04 {
        .btn-smp {
            top: 306px;
        }
    }

    &05 {
        .btn-smp {
            top: 320px;
            height: 50px;
        }
    }

    &06 {
        .btn-smp {
            top: 313px;
            left: 230px;
            width: 50px;
        }
    }

    &07 {
        .btn-smp {
            top: 302px;
        }
    }

    &08 {
        .btn-pc {
            top: 320px;
            left: 23px;
            width: 90px;
            height: 82px;
        }
    }

    &09 {
        .btn-pc {
            top: 457px;
            left: 614px;
            width: 75px;
            height: 48px;
        }
    }

    &10 {
        .btn-pc {
            top: 144px;
            left: 440px;
            z-index: 1001;
            border-radius: 5px;
        }
    }

    &11 {
        .btn-smp {
            top: 332px;
        }
    }
}

.desktop.chrome {
    #user_authentication_screen {
        &09 {
            .btn-pc {
                left: 560px;
            }
        }
    }
}