@use 'sass:map';

#demo {
    &_container {
        @include container;
        min-width: 1366px;
    }

    &_main {
        overflow: hidden;
    }

    &_frame {
        display: flex;
        justify-content: space-between;
        width: 1366px;
        height: 100vh;
    }
}

.fancybox__backdrop {
    opacity: 0.75;
}

#demo_modal2 {

    display: none;
    position: fixed;
    top: 0;
    left: 0;
    align-content: center;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: rgba($color: #484848, $alpha: 0.37);
    width: 100%;
    height: 100%;

    .img_wrap img {
        display: block;
        margin: 0 auto;
    }
}

.pos_relative {
    position: relative;
}

.screen_disable_note {

    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-content: center;
    justify-content: center;
    align-items: center;
    z-index: 999;

    width: 100%;
    height: 100%;

    color: #fff;
    font-size: 16px;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.6);

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: rgba($color: #000, $alpha: 0.36);
        width: 100%;
        height: 100%;
        content: '';
    }

    p {
        margin: 0;
    }
}

.selectbox_customize {
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: #fff;
    padding: 0 11px;
    width: 100%;
    height: 32px;

    &:invalid,
    [disabled] {
        color: #bfbfbf;
    }
}

.print_page {
    position: relative;
    margin: 0 auto;
    width: 960px;

    &::before {
        content: '';
        position: fixed;
        z-index: -1;
        top: 0;
        left: -50%;
        width: 200%;
        height: 100%;
        background-color: rgb(228, 228, 228);
    }

    footer {
        margin-bottom: 0;
    }
}

.window_file {
    position: absolute;
    z-index: 1000;
    width: 660px;
    height: auto;
    box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.5);
    border-radius: 10px;
}


@media only screen and (max-width: map.get($grid-breakpoints, 'md')) {}