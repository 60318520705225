@use 'sass:map';

#whole_push_screen {

    &01 {
        #demo_main {
            overflow: inherit
        }

        .btn-pc {
            top: 485px;
            left: 20px;
            width: 175px;
            height: 32px;
        }
    }

    &02 {
        .btn-pc {
            top: 215px;
            left: 175px;
            width: 370px;
            height: 46px;
        }
    }

    &03 {
        .btn-pc {
            top: 292px;
            left: 171px;
            width: 143px;
            height: 42px;
        }
    }

    &04 {
        .btn-pc {
            bottom: 37px;
            left: 293px;
            width: 214px;
            height: 46px;
        }
    }

    &05 {
        .btn-pc {
            top: 379px;
            left: 144px;
            width: 315px;
            height: 53px;
        }
    }

    &06 {
        .whole-push-step06-list {
            box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
            border-radius: 2px;
            background-color: #fff;
            padding-left: 0;
            width: 340px;
        }

        .whole-push-step06-list li {
            position: relative;
            padding: 5px 12px;
            list-style: none;
        }

        .whole-push-step06-list a {
            position: relative;
            background-color: transparent;
            width: 100%;
            height: auto;
            color: #000000d9;
        }

        .whole-push-step06-list a:before {
            position: absolute;
            top: -38px;
            left: -12px;
            border-radius: 10px;
            background-color: rgba(255, 0, 0, 0.3);
            width: 340px;
            height: 33px;
            content: '';
        }
    }

    &06-1 {
        .btn-pc {
            top: 458px;
            left: 169px;
            width: 132px;
            height: 46px;
        }
    }

    &07 {
        .btn-pc {
            top: 335px;
            left: 301px;
            z-index: 9999;
            width: 317px;
            height: 46px;
        }
    }

    &08 {
        .btn-pc {
            top: 1809px;
            left: 315px;
            width: 293px;
            height: 45px;
        }
    }

    &09 {
        .btn-pc {
            top: 1098px;
            right: 334px;
            width: 170px;
            height: 44px;
        }
    }

    &10 {
        .cdk-overlay-container {
            top: 60px
        }

        .btn-smp {
            top: 240px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 270px;
            height: 59px;
        }
    }

    &11 {
        .cdk-overlay-container {
            top: 60px
        }

        .btn-pc {
            top: 178px;
            left: 437px;
            z-index: 9999;
            width: 238px;
            height: 50px;
        }
    }

}

.desktop.firefox #whole_push_screen {
    &08 {
        .btn-pc {
            top: 1844px;
        }
    }
}