@use 'sass:map';

.push-tutorial-area-text01 {
    position: absolute;
    top: 73px;
    left: 15px;
    color: #e50e0e;
}

.push-tutorial-area-text02 {
    position: absolute;
    top: 130px;
    left: 15px;
    color: #e50e0e;
}

#push_screen {
    &01 {
        .btn-pc {
            top: 240px;
            left: 23px;
            width: 90px;
            height: 82px;
        }
    }

    &02 {
        .btn-pc {
            top: 0px;
            left: 0;
            width: 843px;
            height: 59px;
        }
    }

    &03 {
        .btn-pc {
            top: 1318px;
            left: 328px;
            width: 270px;
            height: 55px;
        }
    }

    &04 {
        @include frame_full_height(1400px);

        .cdk-overlay-container {
            top: 60px
        }

        .btn-pc {
            bottom: 49px;
            left: 267px;
            z-index: 9999;
            width: 265px;
            height: 45px;
        }
    }

    &05 {
        .btn-pc {
            top: 1098px;
            right: 334px;
            width: 170px;
            height: 44px;
        }
    }

    &06 {
        .cdk-overlay-container {
            top: 60px
        }

        .btn-smp {
            top: 240px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 270px;
            height: 59px;
        }
    }

    &07 {
        .cdk-overlay-container {
            top: 60px
        }

        .btn-pc {
            top: 178px;
            left: 437px;
            z-index: 9999;
            width: 238px;
            height: 50px;
        }
    }
}