@use 'sass:meta';
@use 'sass:map';

.btn {

    &-pc,
    &-smp {
        display: block;
        position: absolute;
        border-radius: 10px;
        background-color: rgba($color: #FF0000, $alpha: 0.3);
    }

    &-pc {
        z-index: 3;
        transition: 0.2s;
        width: 240px;
        height: 65px;
    }

    &-smp {
        left: 50%;
        transform: translateX(-50%);
        width: 255px;
        height: 50px;
    }

    &-size_full {
        top: 18px;
        left: 20px;
        width: 98%;
        height: 100%;
    }

    &-size_full_smp {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 270px;
        height: 95%;
    }

    &-size_row {
        left: 40px;
        width: 845px;
        height: 80px;
    }


}

.button_disable {
    display: inline-block;
    position: relative;

    button {
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            background-color: red;
        }
    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
    }
}