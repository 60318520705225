@use 'sass:meta';
@use 'sass:map';

/*
 * @include background( $color, $image, $position, $repeat, $size )
 */
@mixin background($args...) {
    @each $name, $value in meta.keywords($args) {
        @if $name=='color' {
            background-color: $value;
        }

        @if $name=='image' {
            background-image: url('#{$image_url}/#{$value}');
        }

        @if $name=='position' {
            background-position: $value;
        }

        @if $name=='repeat' {
            background-repeat: $value;
        }

        @if $name=='size' {
            background-size: $value;
        }
    }
}

/*
 * @include container( $max-width )
 */
@mixin container($max-width: $container-default) {
    clear: both;
    position: relative;
    z-index: 1;
    width: 100%;

    @if $max-width !=$container-default {
        max-width: $max-width;
    }

    @else {
        max-width: $container-default;
    }

    margin: 0 auto;
}

/*
 * @include hide_element_all()
 */
@mixin hide_element_all() {

    .page_title[_ngcontent-sut-c124],
    .remark_message[_ngcontent-sut-c124],
    .collapse_inactive[_ngcontent-sut-c124],
    .boundary[_ngcontent-sut-c124] {
        display: none;
    }
}

/*
 * @include hide_element($args...)
 */
@mixin hide_element($args...) {
    @each $name, $value in meta.keywords($args) {
        @if $name=='pageTitle' {
            .page_title[_ngcontent-sut-c124] {
                display: none;
            }
        }

        @if $name=='remarkMessage' {
            .remark_message[_ngcontent-sut-c124] {
                display: none;
            }
        }

        @if $name=='collapseInactive' {
            .collapse_inactive[_ngcontent-sut-c124] {
                display: none;
            }
        }

        @if $name=='boundary' {
            .boundary[_ngcontent-sut-c124] {
                display: none;
            }
        }
    }
}

/*
 * @include container( $max-width )
 */
@mixin frame_full_height($height: 100vh) {
    #demo_frame {
        height: $height;

        &__pc {
            height: 100%;

            &_content_inner {
                height: calc(100% - 110px);
            }
        }
    }
}

/*
 * @include print_page_in_frame()
 */
@mixin print_page_in_frame() {
    #demo_frame__pc_content {
        padding-left: 0;
    }

    #demo_frame__pc_content_header,
    #demo_frame__pc_content_sidebar {
        display: none;
    }

    #demo_frame__pc_content_inner {
        padding: 0px;
        width: 100%;
        max-width: 1020px;
        height: calc(100vh - 45px);
        margin: 0 auto;
    }
}