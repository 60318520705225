@use 'sass:map';

#promotion_notice_auto_screen {
    &00 {
        .btn-pc {
            top: 442px;
            left: 6px;
            width: 116px;
            height: 115px;
        }
    }

    &01 {
        .btn-pc {
            top: 573px;
            left: 16px;
            width: 162px;
            height: 41px;
        }
    }

    &02 {
        .content {
            overflow-y: initial;
        }

        .btn-pc {
            top: 256px;
            left: calc((100% - 730px) * 0.5 + 362px);
            z-index: 10000;
            width: 250px;
        }
    }

    &03 {
        .btn-pc {
            top: 701px;
            left: 324px;
            width: 125px;
            height: 40px;
        }
    }

    &04 {
        .content {
            overflow-y: initial;
        }

        .btn-pc {
            top: 297px;
            left: calc((100% - 730px) * 0.5 + 362px);
            z-index: 10000;
            width: 250px;
        }
    }

    &05 {
        .btn-pc {
            top: 993px;
            left: 425px;
            width: 197px;
        }
    }

    &06 {
        .btn-pc {
            top: 0px;
            left: 0;
            width: 837px;
            height: 59px;
        }
    }

    &07 {
        .btn-pc {
            top: 50px;
            left: 370px;
            width: 215px;
            height: 33px;
        }
    }

    &07-1 {
        .btn-pc {
            top: 50px;
            left: 370px;
            width: 215px;
            height: 33px;
        }
    }

    &08 {
        .btn-smp {
            top: 240px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 270px;
            height: 59px;
        }
    }

    &09 {
        .btn-smp {
            top: 146px;
            left: 151px;
            transform: translate(-50%, -50%);
            width: 265px;
            height: 38px;
        }
    }

    &09-1 {
        .btn-smp {
            top: 56px;
            left: 37px;
            transform: translate(-50%, -50%);
            width: 45px;
            height: 38px;
        }
    }

    &10 {
        .btn-pc {
            top: 61px;
            left: 418px;
            width: 80px;
        }
    }

    &11 {
        .btn-pc {
            top: 237px;
            left: 40px;
            width: 845px;
            height: 80px;
        }
    }

    &12 {
        .btn-pc {
            top: 1374px;
            left: 442px;
        }
    }

    &13 {
        .btn-pc {
            top: 1160px;
            left: 566px;
        }
    }

}

.desktop.firefox {
    #promotion_notice_screen {}
}