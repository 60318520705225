@use 'sass:map';

#push_template_screen {

    &01 {
        #demo_main {
            overflow: inherit
        }

        .btn-pc {
            top: 550px;
            left: 20px;
            width: 175px;
            height: 32px;
        }
    }

    &02 {
        .btn-pc {
            top: 156px;
            left: 910px;
            width: 100px;
            height: 40px;
        }
    }

    &03 {
        .btn-pc {
            top: 560px;
            left: 555px;
            width: 242px;
            height: 52px;
        }
    }

    &04 {
        .btn-pc {
            top: 450px;
            left: 920px;
            width: 80px;
            height: 40px;
        }
    }

    &05 {
        .btn-pc {
            top: 103px;
            left: 901px;
            width: 50px;
            height: 40px;
        }
    }

    &06 {
        .btn-pc {
            top: 190px;
            left: 555px;
            width: 240px;
            height: 52px;
        }
    }

    &07 {
        .btn-pc {
            top: 173px;
            left: 591px;
            width: 240px;
            height: 52px;
        }
    }
}



.desktop.firefox #push_template_screen {
    &03 {
        .btn-pc {
            top: 575px;
        }
    }
}