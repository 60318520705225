@use 'sass:map';

#information_registration_qr_screen {
    &01 {
        @include frame_full_height(1870px);
        @include hide_element_all;

        .btn-pc {
            top: 222px;
            left: 543px;
            width: 100px;
            height: 30px;
        }

        .screen_disable_note p {
            margin-bottom: 110px;
        }
    }

    &02 {
        @include frame_full_height(2250px);
        @include hide_element_all;

        .btn-pc {
            top: 1675px;
            left: 340px;
            width: 245px;
            height: 55px;
        }

        .screen_disable_note p {
            margin-bottom: 110px;
        }
    }

    &03 {
        @include hide_element_all;

        .btn-smp {
            top: 411px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &04 {
        @include hide_element_all;

        .btn-smp {
            top: 148px;
            z-index: 3;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }

        #demo_frame__smp_content_scroll {
            .img_wrap:first-child {
                position: absolute;
                // top: 195px;
                left: 0;
                z-index: 2;

                img {
                    border-radius: unset;
                }
            }
        }
    }

    &05 {
        @include hide_element_all;

        .btn-smp {
            top: 433px;
            z-index: 3;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &05-1 {
        @include hide_element_all;

        .btn-smp {
            top: 353px;
            z-index: 3;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &06 {
        @include hide_element_all;

        .btn-smp {
            top: 687px;
            z-index: 3;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &07 {
        @include hide_element_all;

        .btn-smp {
            top: 410px;
            right: 22px;
            left: auto;
            width: 50px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &08 {
        @include hide_element_all;

        .btn-smp {
            top: 469px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &09 {
        @include hide_element_all;

        .btn-smp {
            top: 432px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &10 {
        @include hide_element_all;

        .btn-smp {
            top: 210px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &11 {
        @include hide_element_all;

        .btn-smp {
            top: 295px;
        }

        .btn-smp._b {
            top: 480px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &12 {
        @include hide_element_all;

        .btn-smp {
            top: 494px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }

    &13 {
        @include hide_element_all;

        .btn-smp {
            top: 495px;
        }

        .screen_disable_note p {
            margin-bottom: 180px;
        }
    }
}

.desktop.firefox {
    #information_registration_qr_screen {
        &04 {
            .btn-smp {
                top: 148px;
            }
        }

        &06 {
            .btn-smp {
                top: 626px;
            }
        }
    }
}