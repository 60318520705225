#demo_frame__pc {
    display: flex;
    flex-wrap: wrap;
    @include container(1045px);
    margin: 0 !important;
    width: 1040px;
    height: 100vh;

    &_header {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        z-index: 2;
        background-color: rgba($color: #000000, $alpha: 0.07);
        width: 100%;
        height: 32px;
        color: #fff;

        * {
            margin: 0;
        }

        ._text01 {
            border: 1px solid #fff;
            border-radius: 13px;
            padding: 3px 5px;
            min-width: 125px;
            font-size: 13px;
            line-height: 100%;
            text-align: center;
        }

        ._text02 {
            margin-left: 10px;
            font-size: 15px;
        }
    }

    &_content {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        z-index: 1;
        margin-top: 43px;
        padding-left: 20px;
        width: 100%;

        // &::before {
        //     content: "";
        //     position: absolute;
        //     z-index: -999;
        //     left: 0;
        //     top: 0;
        //     width: 100%;
        //     height: 100%;
        //     background-color: rgba(0, 0, 0, 0.36);
        //     -webkit-transition: opacity 0.2s;
        //     transition: opacity 0.2s;
        //     opacity: 0;
        // }

        &_header {
            display: flex;
            position: relative;
            justify-content: space-between;
            z-index: 2;
            margin-bottom: 4px;
            box-shadow: 0 1px 4px #00152914;
            background: #fff;
            width: 100%;
            height: 64px;
            color: #000000d9;
            line-height: 64px;
        }

        &_sidebar {
            position: relative;
            z-index: 1;
            background: #001529;
            width: 95px;
            height: calc(100vh - 110px);

            &._expand {
                z-index: 2;
                overflow: unset !important;
            }
        }

        &_inner {
            position: relative;
            z-index: 1;
            padding: 10px 10px 0px;
            width: calc(100% - 95px);
            max-width: 925px;
            height: calc(100vh - 110px);
            overflow: auto;
        }

        &:hover {
            .popup {
                opacity: 1;
                z-index: 999;
            }
        }
    }

    &.active {
        #demo_frame__pc_header {
            border-radius: 0 0 27px 27px;
            background-color: #387F41;
            height: 60px;
        }

        .screen_disable_note {
            display: none;
        }

        // &:hover {

        //     #demo_frame__pc_content::before,
        //     .btn-pc {
        //         opacity: 1;
        //         z-index: 3;
        //     }
        // }
    }

    .ant-radio-button-wrapper {
        padding: 0 14px;
    }
}

.pc_header {
    &__logo {
        width: 268px;
    }

    &__info {
        display: flex;

        span {
            padding: 0 10px;
        }

        ._text03 {
            position: relative;

            ul {
                display: none;
                position: absolute;
                top: 45px;
                right: 0;
                margin: 6px 0;
                outline: none;
                box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
                border-radius: 2px;
                background-clip: padding-box;
                background-color: #fff;
                padding: 4px 0;
                width: 100%;
                min-width: 150px;
                line-height: 1.5715;
                list-style: none;
                list-style-type: none;
                text-align: left;

                &::before {
                    position: absolute;
                    opacity: .0001;
                    z-index: -9999;
                    inset: -4px 0 -4px -7px;
                    content: " ";
                }
            }

            li {
                display: flex;
                position: relative;
                align-items: center;
                transition: all .3s;
                cursor: pointer;
                margin: 0;
                background-color: #fff;
                padding: 5px 12px;
                color: #000000a6;
                color: #000000d9;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
            }

            a {
                color: inherit;
            }

            &:hover ul {
                display: block;
            }
        }
    }
}

.pc_sidebar {
    display: flex;
    flex-wrap: wrap;
    transition: all .2s;
    box-shadow: 2px 0 6px #00152959;
    height: 100%;

    ul {
        padding: 0;
        width: 100%;
        list-style: none;
    }

    li {
        position: relative;
        width: 100%;
        height: 80px;
        list-style: none;

        &.active {
            border-color: #006228;
            background-color: #006228;
        }

        a {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            color: #fff;
            text-align: center;

            span {
                display: block;
            }

            ._icon,
            svg {
                margin-bottom: 3px;
                width: 32px;
                height: 32px;
                font-size: xx-large;
            }

            ._text {
                width: 100%;
                font-size: 14px;

                &._small {
                    font-size: 13.3px;
                }
            }
        }

        ._sub_menu {
            position: absolute;
            z-index: 1;
            background-color: #001529;
            width: 175px;
            height: auto;
            margin-top: 5px;
            display: none;

            li {
                height: auto;

                display: block;
                transition: all .3s;
                cursor: pointer;
                margin: 0;
                padding: 5px 12px;
                color: #fff;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                text-align: left;

                // a {
                //     display: block;
                //     transition: all .3s;
                //     cursor: pointer;
                //     margin: 0;
                //     padding: 5px 12px;
                //     color: #fff;
                //     font-weight: 400;
                //     font-size: 14px;
                //     line-height: 22px;
                //     text-align: left;
                // }
            }
        }
    }

    &__top {
        margin-top: 13px;
        margin-bottom: 65px;
    }
}

.safari #demo_frame__pc {
    .ant-radio-button-wrapper {
        padding: 0 12px;
    }
}