@use 'sass:map';

#dtc_alert_screen {
    &01 {
        .cdk-overlay-container {
            top: 60px
        }

        .btn-pc {
            top: 445px;
            left: 335px;
            z-index: 9999;
            width: 85px;
            height: 42px;
        }
    }

    &02 {
        .btn-pc {
            top: 18px;
            left: 798px;
            width: 102px;
        }
    }

    &03 {
        .btn-pc {
            top: 0px;
            left: 0;
            width: 843px;
            height: 50px;
        }
    }

    &04 {
        .btn-pc {
            top: 778px;
            left: 308px;
            width: 270px;
        }
    }

    &05 {
        .cdk-overlay-container {
            top: 60px
        }

        .btn-pc {
            top: 253px;
            left: 711px;
            z-index: 9999;
            width: 102px;
            height: 42px;
        }
    }

    &06 {
        .cdk-overlay-container {
            top: 60px
        }

        .btn-pc {
            top: 159px;
            left: 476px;
            z-index: 9999;
        }
    }

    &07 {
        .cdk-overlay-container {
            top: 60px
        }
    }
}