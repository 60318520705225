@use 'sass:map';

.activeMenu01 .sidemenu01,
.activeMenu02 .sidemenu02,
.activeMenu03 .sidemenu03,
.activeMenu04 .sidemenu04,
.activeMenu05 .sidemenu05,
.activeMenu06 .sidemenu06 {
    border-color: #006228;
    background-color: #006228;
}