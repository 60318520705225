@use 'sass:map';

#maintenance_screen {
    &00 {
        .btn-pc {
            top: 240px;
            left: 23px;
            width: 90px;
            height: 82px;
        }
    }

    &01 {
        .btn-pc {
            top: 0px;
            left: 0;
            width: 843px;
            height: 59px;
        }
    }

    &02 {
        .btn-pc {
            top: 1062px;
            right: 27px;
            width: 187px;
            height: 46px;
        }
    }

    &03 {
        .btn-pc {
            top: 1213px;
            left: 442px;
            width: 238px;
            height: 50px;
        }
    }

    &04 {
        .btn-pc {
            top: 119px;
            left: 332px;
            z-index: 9999;
            width: 210px;
        }
    }

    &05 {
        .btn-pc {
            top: 123px;
            left: 443px;
            z-index: 9999;
            width: 240px;
            height: 50px;
        }
    }

    &06 {
        .btn-pc {
            top: 101px;
            left: 443px;
            z-index: 9999;
            width: 240px;
            height: 50px;
        }
    }

    &07 {
        .btn-pc {
            top: 1595px;
            left: 420px;
            width: 240px;
            height: 50px;
        }
    }

    &08 {
        .btn-pc {
            top: 918px;
            left: 308px;
            width: 310px;
            height: 73px;
        }
    }

    &09 {
        .btn-pc {
            top: 108px;
            left: 249px;
            width: 240px;
            height: 50px;
        }
    }

    &10 {
        .btn-smp {
            top: 37px;
            left: 67%;
            width: 45px;
            height: 35px;
        }
    }

    &11 {
        .btn-smp {
            top: 77px;
            left: 50%;
            width: 275px;
            height: 55px;
        }
    }

    &12 {
        .btn-pc {
            top: 987px;
            right: 27px;
            width: 187px;
            height: 46px;
        }
    }
}