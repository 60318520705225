@use 'sass:map';

#warehousing_maintenance_screen {
    &01 {
        .btn-pc {
            top: 350px;
            left: 648px;
            width: 110px;
            height: 40px;
        }
    }

    &02 {
        .btn-pc {
            top: 2142px;
            left: 30px;
            width: 860px;
        }
    }

    &03 {
        .btn-pc {
            top: 2269px;
            left: 433px;
            width: 210px;
        }
    }

    &04 {
        .btn-pc {
            top: 2391px;
            left: 285px;
            width: 145px;
        }
    }

    &05 {
        .btn-pc {
            top: 2355px;
            left: 464px;
        }
    }

    &05-1 {
        .btn-pc {
            top: 2326px;
            left: 464px;
        }
    }

    &06 {
        @include print_page_in_frame();

        .btn-pc {
            top: 2498px;
            left: 31px;
            width: 65px;
        }
    }

    &07 {
        @include print_page_in_frame();

        .btn-pc {
            top: 2498px;
            right: 27px;
            width: 65px;
        }
    }

    &08 {
        @include print_page_in_frame();

        .btn-pc {
            top: 3493px;
            left: 925px;
            width: 65px;
        }
    }

    &09 {
        @include print_page_in_frame();

        .btn-pc {
            top: 191px;
            left: 490px;
            z-index: 9999;
        }

        .cdk-overlay-container {
            top: 75px;
        }
    }

    &10 {
        .btn-pc {
            top: 351px;
            right: 36px;
            width: 121px;
            height: 34px;
        }
    }

    &11 {
        .btn-pc {
            top: 3789px;
            right: 268px;
            width: 190px;
            height: 50px;
        }
    }

    &12 {
        .btn-pc {
            top: 115px;
            left: 442px;
            z-index: 1002;
        }
    }

    &13 {
        .btn-pc {
            top: 93px;
            left: 442px;
            z-index: 1002;
        }
    }

    &14 {
        .btn-pc {
            top: 1381px;
            left: 442px;
            width: 240px;
        }

        .button_disable {
            margin: 0 auto;
        }
    }

    &15 {
        .btn-smp {
            top: 25px;
            right: 50px;
            left: auto;
            width: 50px;
        }
    }

    &16 {
        .btn-smp {
            top: 103px;
            transform: translate(-50%, -50%);
            width: 270px;
            height: 52px;
        }
    }
}

.desktop.firefox #warehousing_maintenance_screen {
    &08 {
        .btn-pc {
            top: 3508px;
        }
    }
}