@use 'sass:map';

#promotion_notice_24th_screen {
    &01 {
        .btn-pc {
            top: 440px;
            left: 5px;
            width: 119px;
            height: 121px;
        }
    }

    &01-1 {
        .btn-pc {
            top: 890px;
            left: 16px;
            width: 162px;
            height: 41px;
        }
    }

    &01-2 {
        .content {
            overflow-y: initial;
        }

        .btn-pc {
            top: 257px;
            left: calc((100% - 730px) * 0.5 + 362px);
            z-index: 10000;
            width: 250px;
        }
    }

    &01-3 {
        .btn-pc {
            top: 950px;
            left: 400px;
            width: 248px;
        }
    }

    &01-4 {
        .btn-pc {
            top: 61px;
            left: 101px;
            width: 80px;
        }

        .tab[_ngcontent-sut-c141] {
            width: 75px;
        }

        .yellow_rectangle[_ngcontent-sut-c141],
        .yellow_tab[_ngcontent-sut-c141] {
            background-color: #faad14;
        }
    }

    &02 {
        .btn-pc {
            top: 98px;
            left: 145px;
            width: 120px;
        }
    }

    &03 {
        .btn-pc {
            top: 98px;
            left: 266px;
            width: 105px;
        }
    }

    &04 {
        .btn-pc {
            top: 98px;
            left: 369px;
            width: 105px;
        }
    }

    &05 {
        .btn-pc {
            top: 98px;
            left: 36px;
            width: 110px;
        }
    }

    &06 {
        .btn-pc {
            @extend .btn-size_row;
            top: 222px;
        }

        .wrapper,
        .wrapper_card {
            height: auto;
        }
    }

    &07 {
        .btn-pc {
            top: 970px;
            left: 425px;
            width: 270px;
        }
    }

    &08 {
        .btn-pc {
            top: 1402px;
            left: 445px;
            width: 230px;
        }
    }

    &09 {
        .btn-smp {
            top: 240px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 270px;
            height: 59px;
        }
    }

    &10 {
        .btn-smp {
            top: 56px;
            left: 37px;
            transform: translate(-50%, -50%);
            width: 45px;
            height: 38px;
        }
    }

    &11 {
        .btn-smp {
            top: 181px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 270px;
            height: 52px;
        }
    }

    &11-1 {
        .btn-pc {
            top: 93px;
            left: 442px;
            z-index: 1002;
        }
    }

    &12 {
        .btn-pc {
            @extend .btn-size_row;
            top: 244px;
        }
    }

    &13 {
        .btn-pc {
            top: 893px;
            left: 442px;
        }
    }

    &14 {
        .btn-pc {
            top: 680px;
            left: 566px;
        }
    }

    &16 {
        .btn-pc {
            top: 103px;
            right: 33px;
            width: 295px;
            height: 50px;
        }
    }

    &17 {
        .btn-pc {
            top: 328px;
            left: 356px;
        }
    }

    &18 {
        .btn-pc {
            top: 85px;
            left: 249px;
            width: 169px;
            height: 45px;
        }
    }
}