@use 'sass:map';

#promotion_notice_screen {
    &01 {
        .btn-pc {
            top: 442px;
            left: 6px;
            width: 116px;
            height: 115px;
        }
    }

    &01-1 {
        .btn-pc {
            top: 890px;
            left: 16px;
            width: 162px;
            height: 41px;
        }
    }

    &01-2 {
        .content {
            overflow-y: initial;
        }

        .btn-pc {
            top: 256px;
            left: calc((100% - 730px) * 0.5 + 362px);
            z-index: 10000;
            width: 250px;
        }
    }

    &01-3 {
        .btn-pc {
            top: 950px;
            left: 400px;
            width: 248px;
        }
    }

    &01-4 {
        .btn-pc {
            top: 61px;
            left: 338px;
            width: 80px;
        }
    }

    &02 {
        .btn-pc {
            top: 98px;
            left: 148px;
            width: 120px;
        }
    }

    &03 {
        .btn-pc {
            top: 98px;
            left: 269px;
            width: 105px;
        }
    }

    &04 {
        .btn-pc {
            top: 98px;
            left: 371px;
            width: 110px;
        }
    }

    &05 {
        .btn-pc {
            top: 98px;
            left: 35px;
            width: 120px;
        }
    }

    &06 {
        .btn-pc {
            @extend .btn-size_row;
            top: 222px;
        }

        .wrapper,
        .wrapper_card {
            height: auto;
        }
    }

    &07 {
        .btn-pc {
            top: 1571px;
            left: 415px;
            width: 290px;
        }
    }

    &08 {
        .btn-pc {
            top: 1401px;
            left: 440px;
            width: 240px;
        }
    }

    &09 {
        .btn-smp {
            top: 240px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 270px;
            height: 59px;
        }
    }

    &10 {
        .btn-smp {
            top: 56px;
            left: 37px;
            transform: translate(-50%, -50%);
            width: 45px;
            height: 38px;
        }
    }

    &11 {
        .btn-smp {
            top: 181px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 270px;
            height: 52px;
        }
    }

    &12 {
        .btn-pc {
            top: 93px;
            left: 442px;
            z-index: 1002;
        }
    }

    &13 {
        .btn-pc {
            @extend .btn-size_row;
            top: 237px;
        }
    }

    &14 {
        .btn-pc {
            top: 1492px;
            left: 442px;
        }
    }

    &15 {
        .btn-pc {
            top: 1282px;
            left: 566px;
        }
    }
}

.desktop.firefox {
    #promotion_notice_screen {}
}