@use 'sass:map';

.model_screen_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .fancybox__slide {
        padding: 0 !important;
    }

    .has-iframe .fancybox__content {
        padding: 0 !important;
        width: 100% !important;
        height: 100% !important;

        &>.f-button.is-close-btn {
            display: none;
        }
    }
}

.model_menu_show {

    .fancybox__content {
        --fancybox-color: #707070;
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.5);
        border-radius: 25px;
        padding: 0 !important;

        iframe {
            border-radius: 25px;
        }

        &>.f-button.is-close-btn {
            top: 17px;
            right: 17px;
        }
    }
}

.disable a {
    cursor: default;
    background-color: #ccc !important;
    color: #f5f5f5 !important;
}

.modal {
    &-container2 {
        --w: 1227px;
        --h: 698px;

        display: flex;
        position: relative;
        justify-content: space-between;
        margin: 0 auto;
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.5);
        border-radius: 25px;
        width: var(--w);
        height: 100vh;
        max-height: var(--h);
    }

    &-btn-trigger {
        position: absolute;
        top: 15px;
        right: 0;
        z-index: 2;
        width: 63px;
        height: 60px;

        a {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.75);
            border-radius: 51px 0 0 51px;
            width: 100%;
            height: 100%;
            color: #387F42;
            @include background($color: #387F42, $image: 'icon-home.png', $position: center center, $repeat: no-repeat);
        }
    }

    &-btn-inquiry {
        position: absolute;
        right: 0;
        bottom: 15px;
        z-index: 2;
        width: 63px;
        height: 60px;

        a {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.75);
            border-radius: 51px 0 0 51px;
            width: 100%;
            height: 100%;
            color: #387F42;
            @include background($color: #387F42, $image: 'icon-inquiry.png', $position: center center, $repeat: no-repeat, $size: 24px auto);
        }
    }

    &-box {
        display: none;

        &.fancybox__content {
            display: block;
            border: 1px solid #707070;
            border-radius: 25px;
            padding: 0;

            &>.f-button.is-close-btn {
                --f-button-width: 15px;
                --f-button-height: 15px;
                --f-button-color: #0C0D0C;

                top: 25px;
                right: 25px;
            }
        }
    }

    &-side {
        border-radius: 25px 0 0 25px;
        background-color: #3E3E3E;
        width: 252px;
        color: #fff;

        ul {
            display: flex;
            flex-wrap: wrap;
            // align-content: center;
            margin: 0;
            padding: 5px 0 15px 0;
            width: 100%;
            height: 100%;
        }

        li {
            display: flex;
            position: relative;
            align-items: center;
            z-index: 1;
            margin: 5px 0;
            width: 100%;
            height: 103px;
            list-style: none;

            // &:first-child {
            //     margin-top: 25px;
            // }

            // &:last-child {
            //     margin-bottom: 25px;
            // }

            a {
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                color: #fff;
                font-weight: 700;
                font-size: 17px;
                text-align: center;

                &:hover {
                    cursor: pointer;
                }

                span {
                    display: block;
                    width: 100%;
                }

                ._txt {
                    line-height: 1.2;
                }

                ._ico {
                    margin-bottom: 5px;
                    background-position: top center;
                    background-size: auto 100%;
                    background-repeat: no-repeat;
                }

                ._ico01 {
                    height: 32px;
                    @include background($image: 'icons/menu01.png');
                }

                ._ico02 {
                    height: 34px;
                    @include background($image: 'icons/menu02.png');
                }

                ._ico03 {
                    height: 32px;
                    @include background($image: 'icons/menu03.png');
                }

                ._ico04 {
                    height: 38px;
                    @include background($image: 'icons/menu04.png');
                }

                ._ico05 {
                    height: 44px;
                    @include background($image: 'icons/menu05.png');
                }

                ._ico06 {
                    height: 28px;
                    @include background($image: 'icons/menu06.png');
                }
            }

            &.disable {
                a {
                    cursor: default;
                    background-color: transparent !important;

                    ._ico01 {
                        @include background($image: 'icons/menu01-off.png');
                    }

                    ._ico02 {
                        @include background($image: 'icons/menu02-off.png');
                    }

                    ._ico03 {
                        @include background($image: 'icons/menu03-off.png');
                    }

                    ._ico04 {
                        @include background($image: 'icons/menu04-off.png');
                    }

                    ._ico05 {
                        @include background($image: 'icons/menu05-off.png');
                    }
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 17px;
                    z-index: 1;
                    border-radius: 5px;
                    background-color: rgba($color: #387F41, $alpha: 0.53);
                    width: calc(100% - 34px);
                    height: 100%;
                    content: '';
                }

                &::after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(-8deg);
                    z-index: 2;
                    content: 'coming soon...';
                    color: #fff;
                    font-weight: 700;
                    font-size: 17px;
                }
            }

            &.active,
            &:not(.disable):hover {

                &::before,
                &::after {
                    position: absolute;
                    z-index: 0;
                    content: '';
                }

                &::before {
                    top: 0;
                    left: 17px;
                    z-index: -1;
                    border-radius: 5px 0 0 5px;
                    background-color: white;
                    width: calc(100% - 17px);
                    height: 100%;
                }

                &::after {
                    right: -10px;
                    bottom: -9px;
                    transform: rotate(45deg);
                    border-right: 13px solid transparent;
                    border-bottom: 12px solid white;
                    border-left: 13px solid transparent;
                    width: 0;
                    height: 0;
                }

                a {
                    z-index: 1;
                    cursor: pointer;
                    color: #3E3E3E;

                    ._ico01 {
                        @include background($image: 'icons/menu01-on.png');
                    }

                    ._ico02 {
                        @include background($image: 'icons/menu02-on.png');
                    }

                    ._ico03 {
                        @include background($image: 'icons/menu03-on.png');
                    }

                    ._ico04 {
                        @include background($image: 'icons/menu04-on.png');
                    }

                    ._ico05 {
                        @include background($image: 'icons/menu05-on.png');
                    }

                    ._ico06 {
                        @include background($image: 'icons/menu06-on.png');
                    }
                }
            }
        }

    }

    &-content {
        position: relative;
        padding: 30px 55px 0px 55px;
        width: calc(100% - 252px);
        overflow-y: auto;

        &-item {
            display: none;

            &._show {
                display: block;
            }
        }

        a {
            -webkit-transition: 0.5s;
            transition: 0.5s;

            &:hover {
                opacity: .8;
            }
        }

        .disable a {
            cursor: default;
            background-color: #ccc !important;
            color: #f5f5f5 !important;
        }
    }

    &_item_group {
        display: flex;
        margin-bottom: 6px;

        ._number {
            position: relative;
            width: 45px;

            p {
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                z-index: 1;
                margin: 0;
                border-radius: 50%;
                background-color: #387F42;
                width: 100%;
                height: 45px;
                color: #fff;
                font-weight: 700;
                font-size: 16px;
                text-align: center;
            }

            &::before {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: #F0F0F0;
                width: 33px;
                height: calc(100% - 40px);
                content: '';
            }

            &::after {
                position: absolute;
                bottom: -16px;
                left: -10px;
                transform: rotate(-145deg);
                border-right: 2px solid transparent;
                border-bottom: 29px solid white;
                border-left: 55px solid transparent;
                content: "";
            }
        }

        ._content {
            padding-left: 22px;
            width: calc(100% - 45px);

            &._w525 {
                max-width: 570px;

                ._subgroup {
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    padding: 7px 10px 7px 20px;

                    ._title {
                        margin-bottom: 0;
                    }
                }

                ._sublink {
                    width: 330px;

                    ._link {
                        a {
                            min-width: 160px;
                        }
                    }
                }
            }

            ._title {
                margin-bottom: 13px;
                color: #387F42;
                font-weight: 700;
                font-size: 21px;
                line-height: 120%;
            }

            ._link {
                margin-bottom: 12px;

                a {
                    display: block;
                    border-radius: 45px;
                    background-color: #00813A;
                    padding: 17px 10px;
                    width: 100%;
                    color: #fff;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 118.5%;
                    text-align: center;
                }
            }
        }

        ._content_flex {
            display: flex;
            justify-content: space-between;
            align-items: center;

            ._btn {
                position: relative;
                width: 225px;

                &:before {
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    z-index: 2;
                    border-radius: 17px;
                    background: #C60808;
                    width: 63px;
                    height: 33px;
                    content: "NEW";
                    color: #fff;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 33px;
                    text-align: center;
                    text-transform: uppercase;
                }

                a {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 13px;
                    background-color: #00813A;
                    padding: 10px;
                    width: 100%;
                    min-height: 90px;
                    color: #fff;
                    font-weight: bold;
                    font-size: 16px;
                }
            }

            ._subgroup {
                max-width: 555px;
            }
        }

        ._subgroup {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 12px;
            border-radius: 22px;
            background-color: #EBEBDF;
            padding: 13px 15px 11px;

            ._title {
                margin-bottom: 8px;
                font-size: 16px;
            }

            &._w555 {
                width: 555px;
            }
        }

        ._sublink {
            display: flex;
            width: 100%;

            ._link {
                margin: 0 3px 0;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    padding: 10px 10px;
                    width: auto;
                    min-width: 170px;
                }
            }
        }
    }

    &_groups_2col {
        display: flex;
        flex-wrap: wrap;
        gap: 30px 20px;

        ._item {
            border-radius: 22px;
            background-color: #EBEBDF;
            padding: 20px 30px 33px;
            width: 405px;

            h3 {
                margin-bottom: 12px;
                color: #387F42;
                font-weight: 700;
                font-size: 25px;
            }

            ._txt {
                margin-bottom: 33px;
                color: #3E3E3E;
                font-weight: 500;
                font-size: 16px;
                line-height: 1.4;

                p {
                    margin-bottom: 0;

                    &._ico {
                        position: relative;
                        padding-left: 20px;

                        &::before {
                            position: absolute;
                            top: 6px;
                            left: 0;
                            background-color: #387F41;
                            width: 9px;
                            height: 9px;
                            content: '';
                        }
                    }
                }
            }

            ._img {
                margin-bottom: 25px;

                img {
                    display: block;
                    margin: 0 auto;
                    max-height: 185px;
                }
            }

            ._btn {
                margin-bottom: 0;
                color: #00813A;
                font-weight: 700;
                font-size: 27px;
                text-align: center;

                a {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 27px;
                    background-color: #00813A;
                    padding: 10px;
                    width: 325px;
                    min-height: 76px;
                    color: #fff;
                    font-size: 21px;
                }
            }
        }
    }

    &-menupage {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        box-sizing: border-box;
        border-radius: 25px;
        background-color: #fff;
        padding: 40px 60px 35px;
        width: 965px;
        // min-height: 495px;
        // max-height: 640px;

        &-overlay {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
            background-color: rgba($color: #1D1D1B, $alpha: 0.36);
            width: 100%;
            height: 100%;
        }

        .modal-progress {
            margin-bottom: 35px;

            ul {
                display: flex;
                position: relative;
                justify-content: center;
                padding: 0;
                overflow: hidden;
            }

            li {
                position: relative;
                margin-right: 185px;
                list-style: none;
                text-align: center;

                &:first-child {
                    &::before {
                        position: absolute;
                        top: 18px;
                        left: -100%;
                        border-bottom: 12px dotted #387F42;
                        width: 200px;
                        height: 0;
                        content: '';
                    }
                }

                &._first:first-child::before {
                    display: none;
                }

                &._last::after {
                    top: 16px;
                    border: none !important;
                    background-color: #fff !important;
                    height: 15px !important;
                }

                &:last-child {
                    margin-right: 0;

                    &::after {
                        border-bottom: 12px dotted #D3D3D3;
                        background-color: transparent;
                        height: 0;
                    }
                }

                &::after {
                    position: absolute;
                    top: 18px;
                    left: 50%;
                    background-color: #D3D3D3;
                    width: 300px;
                    height: 11px;
                    content: '';
                }

                &._done {

                    ._number,
                    &::after {
                        background-color: #387F42;
                    }
                }

                ._number {
                    display: flex;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;
                    margin: 0;
                    margin: 0 auto 15px;
                    border-radius: 50%;
                    background-color: #D3D3D3;
                    width: 45px;
                    height: 45px;
                    color: #fff;
                    font-weight: 700;
                    font-size: 16px;
                    text-align: center;
                }

                ._text {
                    width: 100%;
                    color: #387F42;
                    font-weight: 700;
                    font-size: 16px;
                }

            }
        }

        &-content {
            margin-bottom: 25px;
            border-radius: 17px;
            background-color: #EBEBDF;
            padding: 22px 33px 30px 33px;

            ._title {
                margin-bottom: 20px;
                color: #387F42;
                font-weight: 700;
                font-size: 23px;
                text-align: center;
            }

            ._group_link {
                display: flex;
                flex-wrap: wrap;
                gap: 25px;

                ._link {
                    margin-bottom: 0;
                    width: 100%;

                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 30px;
                        background-color: #00813A;
                        width: 48%;
                        height: 100px;
                        color: #fff;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 1.2;
                        text-align: center;
                        text-decoration: none;
                    }
                }

                &._type01 {
                    justify-content: center;

                    ._link {
                        width: auto;

                        a {
                            border-radius: 45px;
                            width: 370px;
                            height: 185px;
                        }
                    }
                }

                &._type02 {
                    justify-content: center;

                    ._link {
                        width: 410px;

                        a {
                            border-radius: 45px;
                            width: 100%;
                            height: 153px;
                        }
                    }
                }

                &._type03 {
                    justify-content: center;

                    ._link {
                        width: 370px;

                        a {
                            border-radius: 25px;
                            width: 100%;
                            height: 84px;
                        }
                    }
                }
            }
        }

        &-button_groups {
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto;
            width: 433px;

            ._link {
                margin-bottom: 10px;
                width: 100%;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    width: 100%;
                    height: 60px;
                    color: #fff;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 1.2;
                    text-align: center;
                    text-decoration: none;
                }

                &._btn01 a {
                    background-color: #00813A;
                }

                &._btn02 a {
                    background-color: #8C8C8C;
                }
            }
        }

        &._popup {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            width: 375px;
            min-height: 165px;
            overflow: hidden;
            text-align: center;

            ._title {
                margin: 10px 0 14px;
                width: 100%;
                color: #387F42;
                font-weight: 600;
                font-size: 28px;
            }

            ._text {
                margin-bottom: 20px;
                padding: 25px 10px 0;
                width: 100%;
                color: #387F42;
                font-weight: 600;
                font-size: 15px;
                line-height: 1.8;

                p {
                    margin: 0;
                }
            }

            ._img {
                margin: 0 auto 30px;
            }

            ._btn {
                width: 100%;
                text-align: center;

                a {
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    background-color: #387F42;
                    width: 100%;
                    height: 45px;
                    color: #fff;
                    font-weight: 700;
                    font-size: 16px;
                    text-decoration: none;

                    ._ico {
                        margin-right: 25px;
                        width: 25px;
                        height: 19px;
                        @include background($image: 'modal-menu/icon-check.png', $position: center center, $repeat: no-repeat);
                    }

                    &:hover {
                        opacity: .8;
                    }
                }
            }

            ._group01 {
                display: flex;
                justify-content: space-between;
                padding: 0 30px 30px;

                ._content {
                    width: 522px;

                    ._item {
                        position: relative;
                        margin-bottom: 10px;
                        border-radius: 22px;
                        background-color: #F0F0F0;
                        padding: 14px 10px 14px 50px;
                        text-align: left;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    ._num {
                        display: flex;
                        position: absolute;
                        top: 14px;
                        left: 10px;
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        background-color: #387F41;
                        width: 34px;
                        height: 34px;
                        color: #fff;
                        font-weight: 700;
                        font-size: 19px;
                    }

                    h4 {
                        margin: 6px 0 12px;
                        color: #387F42;
                        font-weight: 700;
                        font-size: 21px;
                    }

                    p {
                        position: relative;
                        margin: 0;
                        padding-left: 12px;
                        line-height: 1.6;

                        &::before {
                            position: absolute;
                            top: 7px;
                            left: 0;
                            background-color: #387F41;
                            width: 9px;
                            height: 9px;
                            content: '';
                        }
                    }
                }

                ._img {
                    box-sizing: border-box;
                    margin-bottom: 0;
                    margin-left: 10px;
                    border-radius: 14px;
                    background-color: #EBEBDF;
                    padding: 25px 20px;
                    width: 478px;

                    h4 {
                        margin: 0 0 15px;
                        color: #387F42;
                        font-weight: 700;
                        font-size: 19px;
                        text-align: left;
                    }
                }
            }
        }
    }
}

#menu-home {
    #model-menu02 .modal_groups_block02 {
        margin-top: 50px;
    }

    #model-menu03 .modal_groups_block02 {
        margin-top: 50px;
    }

    #model-menu04 .modal_groups_2col {
        margin-top: 50px;
    }

    #model-menu05 .modal_groups_2col {
        margin-top: 0;
        margin-bottom: 15px;

        ._item {
            h3 {
                text-align: center;
            }

            ._btn a {
                min-height: 108px;
            }
        }
    }

    #model-menu06 .modal_groups_2col {
        margin-top: 160px;

        ._item {
            padding: 20px 20px 33px;

            ._txt {
                min-height: 103px;
                line-height: 1.6;
            }

            ._btn a {
                padding: 10px 0;
                width: 100%;
                min-height: 76px;
            }
        }
    }
}

#menu01 {

    &-5,
    &-6,
    &-8,
    &-9,
    &-10,
    &-11,
    &-12 {
        .modal-menupage .modal-progress li:first-child::before {
            left: -245%;
        }
    }

    &-6 {
        .modal-menupage .modal-menupage-content ._title {
            margin-top: 20px
        }
    }
}

#menu04 {
    &-1 {
        .modal-menupage._popup {
            padding: 25px 0 0;
            width: 1073px;

            ._text {
                padding: 0 30px;
                color: #181818;
            }

            ._btn a {
                height: 75px;
                font-size: 23px;
            }
        }
    }

    &-3 {
        .modal-menupage {
            padding: 64px 70px 52px;
            width: 965px;
            min-height: 410px;
        }
    }
}

#menu05 {
    &-1 {
        .modal-menupage._popup {
            padding: 25px 0 0;
            width: 847px;

            ._text {
                padding: 0 100px;
                color: #181818;
            }

            ._btn a {
                height: 75px;
                font-size: 23px;
            }
        }
    }

    &-2 {
        .modal-menupage._popup {
            width: 667px;
        }
    }

}

#modal-maintenance {
    .modal-container2 {
        // --w: 1037px;
        // --h: 530px;
        --w: 800px;
        --h: 330px;
        border-top: solid 50px #387F42;

        background-color: #fff;
    }
}

.modal-maintenance {
    margin: 0 auto;
    width: 876px;

    &-container {
        text-align: center;
    }

    &-title {
        margin: 52px 0 18px;
        color: #387F42;
        font-weight: 700;
        font-size: 33px;
    }

    &-text {
        margin-bottom: 35px;
        font-size: 17px;
    }

    &-detail {
        margin-bottom: 25px;
        border-radius: 22px;
        background-color: #EBEBDF;
        padding: 22px 33px 5px 33px;

        ._p1 {
            margin-bottom: 18px;
            color: #387F42;
            font-weight: 700;
            font-size: 21px;

            span {
                color: #2B342C;
                font-size: 13px;
            }
        }

        ._p2 {
            margin-bottom: 12px;
            color: #387F42;
            font-weight: 700;
            font-size: 33px;
        }

        ._p3 {
            font-size: 13px;
        }
    }
}

#modal-inquiry {
    .modal-container2 {
        --w: 750px;

        background: #fff;
        padding: 50px;
        overflow-y: auto;
    }

    .hbspt-form {
        width: 100%;

        iframe {
            margin-bottom: 30px;
        }
    }
}

.modal_groups_block01 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    border-radius: 31px;
    background: #F0F0E7;
    padding: 20px 30px;

    ._des {
        margin-bottom: 0;
        max-width: 385px;
        color: #3E3E3E;
        font-size: 16px;
    }

    ._btn {
        margin-bottom: 0;
        font-weight: 700;
        text-align: center;

        a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 32px;
            background-color: #00813A;
            padding: 24px 10px 20px;
            width: 370px;
            min-height: 76px;
            color: #fff;
            font-size: 24px;
        }
    }
}

.modal_groups_block02 {
    margin-top: 78px;
    border-radius: 22px;
    background: rgba(235, 235, 223, 0.75);
    padding: 55px 40px 37px;

    ._title {
        margin-bottom: 15px;
        color: #387F42;
        font-size: 25px;
        line-height: 100%;
        text-align: center;
    }

    ._des {
        margin-bottom: 30px;
        color: #3E3E3E;
        font-size: 16px;

        &._mbt01 {
            margin-bottom: 48px;
        }
    }

    ._btn {
        margin-bottom: 0;
        font-weight: 700;
        text-align: center;

        &._font24 {
            a {
                font-size: 24px;
            }
        }

        a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 45px;
            background-color: #00813A;
            padding: 15px 10px 10px;
            width: 100%;
            min-height: 76px;
            color: #fff;
            font-size: 21px;
        }
    }

    ._img {
        margin-bottom: 22px;
        text-align: center;

        &._mbt01 {
            margin-bottom: 45px;
        }
    }
}

.modal-menupage-content-style01 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    ._group01 {
        position: relative;
        border-radius: 23px;
        background: #00813A;
        width: 375px;
        color: #fff;
        font-size: 25px;
        text-align: center;

        ._text_new {
            position: absolute;
            top: -5px;
            left: -5px;
            margin: 0;
            border-radius: 17px;
            background: #C60808;
            padding: 16px 0 12px;
            width: 100px;
            font-weight: bold;
        }

        ._text_des {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            box-sizing: border-box;
            margin: 0;
            padding-top: 133px;
            width: 100%;
            height: 100%;
            color: #fff;
            font-weight: bold;
            text-decoration: none;
        }
    }

    ._group02 {
        border-radius: 21px;
        background: #EBEBDF;
        padding: 21px 25px 16px;
        width: 392px;

        ._title {
            margin-top: 0;
            margin-bottom: 15px;
            color: #387F42;
            font-weight: bold;
            font-size: 24px;
        }

        ._link {
            margin: 0 0 11px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                display: block;
                border-radius: 32px;
                background: #00813A;
                padding: 22px 0 16px;
                width: 100%;
                color: #fff;
                font-weight: bold;
                font-size: 22px;
                text-align: center;
                text-decoration: none;
            }
        }
    }
}