@use 'sass:meta';
@use 'sass:map';

.popup {
    position: absolute;
    z-index: 5;
    min-width: 310px;
    min-height: 70px;
    padding: 17px 20px 17px 40px;

    background-color: rgba($color: #146D39, $alpha: 0.8);
    border-radius: 20px;
    border: 1px solid #fff;
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.8));

    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    opacity: 0;
    z-index: -999;
    -webkit-transition: 0.5s;
    transition: 0.5s;

    color: #fff;
    font-size: 13px;
    text-align: center;
    word-break: keep-all;

    &::before {
        content: '';
        position: absolute;
        left: 12px;
        top: -25px;
        width: 42px;
        height: 44px;
        @include background($image: 'popup-icon01.png', $position: center center, $repeat: no-repeat);
    }

    &::after {
        content: '';
        position: absolute;
    }

    p {
        margin: 0;
    }

    &._shape01 {
        &::after {
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 0px 15px 55px 0px;
            border-color: transparent transparent rgba($color: #146D39, $alpha: 0.8) transparent;
            transform: rotate(145deg);
            right: 10px;
            bottom: -42px;
        }
    }

    &._shape02 {
        &::after {
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 0px 15px 55px 0px;
            border-color: transparent transparent rgba($color: #146D39, $alpha: 0.8) transparent;
            transform: rotate(235deg);
            left: -13px;
            bottom: -35px;
        }
    }
}
