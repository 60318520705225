/*
 * BREAKPOINT
 */
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px) !default;


/*
 * WIDTH
 */
$container-default: 1366px;

