@use 'sass:map';

#warehousing_maintenance_24th_screen {
    &01 {
        .btn-pc {
            top: 350px;
            left: 648px;
            width: 110px;
            height: 40px;
        }
    }

    &02 {
        .btn-pc {
            top: 2012px;
            left: 30px;
            width: 860px;
        }
    }

    &03 {
        .btn-pc {
            top: 2140px;
            left: 433px;
            width: 210px;
        }
    }

    &04 {
        .btn-pc {
            top: 2261px;
            left: 285px;
            width: 145px;
        }
    }

    &05 {
        .btn-pc {
            top: 2228px;
            left: 464px;
        }
    }

    &05-1 {
        .btn-pc {
            top: 1784px;
            left: 464px;
        }
    }

    &06 {
        @include print_page_in_frame();

        .btn-pc {
            top: 2174px;
            left: 31px;
            width: 65px;
        }
    }

    &07 {
        @include print_page_in_frame();

        .btn-pc {
            top: 2174px;
            right: 27px;
            width: 65px;
        }
    }

    &08 {
        @include print_page_in_frame();

        .btn-pc {
            top: 3139px;
            left: 925px;
            width: 65px;
        }
    }

    &09 {
        @include print_page_in_frame();

        .btn-pc {
            top: 191px;
            left: 490px;
            z-index: 9999;
        }

        .cdk-overlay-container {
            top: 75px;
        }
    }

    &10 {
        .btn-pc {
            top: 351px;
            right: 36px;
            width: 121px;
            height: 34px;
        }
    }

    &11 {
        .btn-pc {
            top: 3143px;
            right: 268px;
            width: 190px;
            height: 50px;
        }
    }

    &12 {
        .btn-pc {
            top: 114px;
            left: 442px;
            z-index: 1002;
        }
    }

    &13 {
        .btn-pc {
            top: 160px;
            left: 442px;
            z-index: 1002;
        }
    }

    &14 {
        .btn-pc {
            top: 1486px;
            left: 357px;
            z-index: 1002;
            width: 187px;
            height: 53px;
        }

        .selectbox_customize {
            max-width: 340px;
        }
    }

    &15 {
        .btn-pc {
            top: 7287px;
            left: 430px;
            width: 240px;
        }
    }

    &16 {
        .btn-pc {
            top: 1681px;
            left: 442px;
            width: 240px;
        }

        .pdf_img {
            margin: 10px 0 10px;
        }
    }

    &17 {
        .btn-pc {
            top: 168px;
            left: 332px;
            z-index: 9999;
            width: 210px;
        }

        .pdf_img {
            margin: 10px 0 10px;
        }

        .cdk-overlay-container {
            top: 75px;
        }
    }

    &18 {
        .btn-pc {
            top: 3852px;
            left: 295px;
            width: 240px;
        }

        .btn-pc._b {
            top: 3827px;
            left: 95px;
            width: 190px;
        }

        .pdf_img {
            margin: 10px 0 10px;
        }
    }

    &19 {
        .btn-pc {
            top: 1381px;
            left: 442px;
            width: 240px;
        }

        .button_disable {
            margin: 0 auto;
        }
    }

    &20 {
        .btn-smp {
            top: 25px;
            right: 50px;
            left: auto;
            width: 50px;
        }
    }

    &21 {
        .btn-smp {
            top: 103px;
            transform: translate(-50%, -50%);
            width: 270px;
            height: 52px;
        }
    }
}

.desktop.firefox {
    #warehousing_maintenance_24th_screen {
        &08 {
            .btn-pc {
                top: 3508px;
            }
        }

        &11 {
            .btn-pc {
                top: 3359px;
            }
        }
    }
}